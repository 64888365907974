<div class=" card col-12 mt-5" *ngIf="attiva" >
<div class="labelDomanda card-header">
    <strong>
        {{domanda_dettaglio.testo}}
        </strong>
</div>
<div class="card-body">
    <textarea class="form-control" id="testoLibero" rows="2" [(ngModel)]="risposta" (input)="onInput()" [required]="attiva"></textarea>
</div> 
<div *ngIf="risposta == ''" class="card-footer text-body-secondary">
    *Il campo è obbligatorio.
</div> 
</div>
