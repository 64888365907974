import { ReplaceableComponentsService } from '@abp/ng.core';
import { Component, HostListener } from '@angular/core';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { AziendaService } from '@proxy/aziende-service';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent {

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
  
  }

