<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Dettagli</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="modal-body">
    <table class="table table-striped">
		<thead>
			<tr>
				<th>Sezione</th>
				<th>Indice di completamento</th>
			</tr>
		</thead>
        <tbody *ngFor="let sezione_sondaggio of sezioni_sondaggio">
            <tr>
                <td>{{sezione_sondaggio.numero_sezione}}</td>
				<td>{{sezione_sondaggio.risposte_date}} / {{sezione_sondaggio.domande_totali}}</td>
            </tr>
        </tbody>
    </table>
</div>
