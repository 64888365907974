<div class="container">
<div class="aggiungiReferente">
    <button type="button" class="btn btn-primary" *abpPermission="'plb.Referente.Update'" (click)="openAggiungiReferenteModal(AggiungiReferente)">Aggiungi referente +</button>
</div>
</div>
<div #listaReferenti id="listaReferenti">
<div  class="container" *ngIf="referentiAziendali.length > 0; else noReferenti">
<table class="table table-striped">
	<thead>
		<tr>
			<th>Referente</th>
			<th>Indirizzo mail principale</th>
			<th>Indirizzo mail secondario</th>
			<th>Cellulare</th>
		</tr>
	</thead>
    <tbody  *ngFor="let referente of referentiAziendali">
        <tr>
            <td>{{referente.surname + " " + referente.name}}</td>
            <td>{{referente.email}}</td>
			<td>{{getEmailSecondariaReferente(referente.id) ?? null}}</td>
            <td>{{referente.phoneNumber}}</td>
        </tr>
    </tbody>
</table>
</div>
</div>
<ng-template #noReferenti>
    <div>
        <p>Nessun Referente da mostrare</p>
    </div>    
</ng-template>

<ng-template #AggiungiReferente let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Aggiungi referente</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
	</div>
	<div class="container" >
	<form [formGroup]="cercaReferenteForm" (ngSubmit)="cercaRefonSubmit()">
		<div class="row">
			<div class="col-10 mb-3">
					<label class="form-label">Indirizzo e-mail</label>
					<input type="email" class="form-control" formControlName="emailReferente" >
			</div>
		</div>
	</form>
</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-lg btn-outline-primary" (click)="c('Save click')">Aggiungi</button>
        <button type="button" class="btn btn-lg btn-outline-primary"(click)="openCreaReferenteModal(CreaReferente)" >Crea nuovo referente</button>
	</div>
</ng-template>

<ng-template #CreaReferente let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Crea referente</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
	</div>
	<div class="container" >
	<form [formGroup]="creaReferenteForm" (ngSubmit)="aggiungiRefonSubmit()">
		<div class="row">
			<div class="col-10">
					<label class="form-label">Nome utente*</label>
					<input type="text" class="form-control" formControlName="username" >
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Password*</label>
					<input type="password" class="form-control" formControlName="password" >
					<p class="disclaimer" *ngIf="!checkPassword(creaReferenteForm.value.password)">La password deve essere lunga almeno 8 caratteri e deve contenere almeno un carattere maiuscolo, uno minuscolo, un numero ed un carattere speciale</p>
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Nome*</label>
					<input type="text" class="form-control" formControlName="nome" >
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Cognome*</label>
					<input type="text" class="form-control" formControlName="cognome" >
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Indirizzo e-mail*</label>
					<input type="email" class="form-control" formControlName="email" >
					<p class="disclaimer" *ngIf="checkUtenteEsistente(creaReferenteForm.value.email)">Email Esistente</p>
			</div>
		</div>
		<div class="row">
			<div class="col-10">
					<label class="form-label">Indirizzo e-mail secondario</label>
					<input type="email" class="form-control" formControlName="email_secondaria" >
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Numero di telefono*</label>
					<input type="tel" class="form-control" formControlName="telefono" >
			</div>
		</div>
	</form>
</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-lg btn-outline-primary" (click)="c('Save click')" [disabled]="creaReferenteForm.invalid">Crea</button>
	</div>
</ng-template>

<ng-template #utenteNonEsiste>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Attenzione</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="onChiudiModale()"></button>
	</div>
	<div class="modal-body">
		La mail inserita non corrisponde a nessuna utenza esistente.
	</div>
</ng-template>

<ng-template #utenteGiàReferente>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Attenzione</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="onChiudiModale()"></button>
	</div>
	<div class="modal-body">
		La mail inserita corrisponde ad un' utenza già referente di questa azienda.
	</div>
</ng-template>

