import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AziendaDto } from '@proxy/aziende-dto';
import { PercentPipe, DatePipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, SlicePipe } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {NgbModal, NgbModalConfig, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import { PermissionDirective } from '@abp/ng.core';
import { CreaSondaggioDto, SondaggioDto } from '@proxy/sondaggi-dto';
import { SondaggioService } from '@proxy/sondaggi-service';
import { QuestionariDto, QuestionariService } from '@proxy';
import { QuestionarioService } from '@proxy/questionari-service';
import { QuestionarioDto } from '@proxy/questionari-dto';
import { FileExportService } from '@proxy/controllers';
import { DomSanitizer } from '@angular/platform-browser';
import { DownloadSezioneComponent } from './download-sezione/download-sezione.component';
import { ErogaModalComponent } from './eroga-modal/eroga-modal.component';
import { DettaglioSondaggioComponent } from '../../dettaglio-sondaggio/dettaglio-sondaggio.component';
import { StatoSondaggio } from 'src/app/shared/shared.module';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sondaggi-azienda',
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, ReactiveFormsModule, NgSwitch, NgSwitchCase, NgSwitchDefault, DatePipe, PermissionDirective,
    NgbDropdownModule, PercentPipe, SlicePipe, DownloadSezioneComponent],
  templateUrl: './sondaggi-azienda.component.html',
  styleUrl: './sondaggi-azienda.component.scss'
})
export class SondaggiAziendaComponent {

  @Input() datiAzienda: AziendaDto
  @Output() aziendaAggiornata = new EventEmitter()
  private unsubscribe$ = new Subject<void>();
  sondaggi: SondaggioDto[] = []
  intervistatiFile: File
  intervistatiString: string[]
  listaQuestionari: QuestionarioDto[] = []
  sondaggioSelezionato = {} as SondaggioDto
  fileSelezionato: any


  creaSondaggioForm = new FormGroup({
    nome: new FormControl('',[Validators.required, Validators.minLength(1)]),
    data_di_inizio: new FormControl<Date>(null,[Validators.required]),
    data_di_scadenza: new FormControl<Date>(null,[Validators.required]),
    questionario: new FormControl('',[Validators.required]),
    lista_intervistati: new FormControl([Validators.required])
  });

  fileReader = new FileReader()

  constructor(private sondaggioService: SondaggioService, config: NgbModalConfig,
		private modalService: NgbModal, private questionarioService: QuestionarioService,
    private fileExportService: FileExportService,
    private sanitizer: DomSanitizer){
      config.backdrop = 'static';
      config.keyboard = true;
      config.size = 'xl'
      config.centered= true;
  }

  ngOnInit(): void {
  this.creaSondaggioForm.reset()
  this.getSondaggi();
  this.getQuestionari()
  }

  openAggiungiSondaggioModal(content){
    this.modalService.open(content).result.then((result)=>{
      this.onSubmit();
    })
  }

  onOpenMenuEsporta(content){
    this.modalService.open(content);
  }

  onClickonEroga(sondaggio_selezionato: SondaggioDto){
    const modalRef = this.modalService.open(ErogaModalComponent);
    modalRef.result.then((res) => {
      if (res == true) {
        this.erogaSondaggio(sondaggio_selezionato.id)
      }
    });
  }

  onClickonDettaglio(sondaggio_id: string){
    const modalRef = this.modalService.open(DettaglioSondaggioComponent)
    modalRef.componentInstance.sondaggio_ID = sondaggio_id
  }

  erogaSondaggio(sondaggio_id: string){
    this.sondaggioService.erogaIntervisteSondaggioBySondaggio_id(sondaggio_id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      error: (e) => { console.log("Impossibile erogare il sondaggio: ", e) },
      complete: () => {
        console.log("Sondaggio erogato con successo")
      }
    })
  }

  getStatoSondaggio(sondaggio: SondaggioDto){
    if(Date.now() > Date.parse(sondaggio.data_di_scadenza)){
      return StatoSondaggio.SCADUTO
    }
    if(Date.now() < Date.parse(sondaggio.data_di_inizio)){
      return StatoSondaggio.CREATO
    }
    if(sondaggio.stato_di_completamento == 1){
      return StatoSondaggio.COMPLETATO
    }
    else{
      return StatoSondaggio.IN_CORSO
    }
  }

  attivaEroga(stato_sondaggio: StatoSondaggio){
    if (stato_sondaggio == StatoSondaggio.CREATO){
      return true
    }
    else {
      return false
    }
  }



  

  getSondaggi(){
    this.sondaggioService.getList(this.datiAzienda.id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => { this.sondaggi = response },
      error: () => { console.debug("Errore nel popolamento della lista sondaggi") },
      complete: () => { console.debug("caricamento sondaggi completato") }
    })
  }

  onSelectSondaggio(sondaggio: SondaggioDto){
    this.sondaggioSelezionato = sondaggio
  }



  getQuestionari(){
    this.questionarioService.get().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => {this.listaQuestionari = response},
      error: () => {console.debug("Errore nel popolamento della lista questionari")},
      complete: () => {console.debug("OK")}
    })
  }

  CreaSondaggio(sondaggio: CreaSondaggioDto) {
    this.sondaggioService.create(sondaggio).pipe(takeUntil(this.unsubscribe$)).subscribe({
      error: (err) => { console.debug(err) },
      complete: () => {
        this.creaSondaggioForm.reset();
        this.getSondaggi()
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit(){
    var sondaggio = new Object() as CreaSondaggioDto
    sondaggio.nome = this.creaSondaggioForm.value.nome
    sondaggio.data_di_inizio = this.creaSondaggioForm.value.data_di_inizio.toString()
    sondaggio.data_di_scadenza = this.creaSondaggioForm.value.data_di_scadenza.toString()
    sondaggio.aziendaId = this.datiAzienda.id
    sondaggio.email_intervistati = this.intervistatiString
    sondaggio.questionario_selezionato = this.creaSondaggioForm.value.questionario
    this.CreaSondaggio(sondaggio);
  }

  onFileUploading(event: Event){
    const newFile = (event.target as HTMLInputElement).files[0]
    if (newFile){
      this.intervistatiFile = newFile
    }
    this.csvToArray(this.intervistatiFile)
  }

  csvToArray(file: File){
    this.fileReader.onloadend = (e)=>{
      this.intervistatiString = this.fileReader.result.toString().split(";")
    }
    this.fileReader.readAsText(file)

  }
}
