<div class=" card mt-5" [hidden]="!attiva">
    <div class="card-header">
      <div class="mb-2">
        <strong>
          {{domanda_dettaglio.testo}}
        </strong>
      </div>
      <div style="display: flex; justify-content: center;">
        <img *ngIf="domanda_dettaglio.id_immagine != null" id="{{domanda_dettaglio.id_immagine}}" src="\assets\images\img_domande\{{domanda_dettaglio.id_immagine}}.png" class="img-fluid border-3" alt="...">
      </div>
    </div>
    <div class="card-body">
    <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col"  *ngFor="let opzione of domanda_dettaglio.risposte_possibili">
    <img id="{{opzione.nome_domanda_relazione}}" src="\assets\images\img_domande\{{opzione.id_immagine}}.png" class="img-fluid rounded border border-5" alt="..." *ngIf="opzione.id_immagine != null;else titoloOpzione" >
    <ng-template #titoloOpzione>
        <div class="form-check" >
            <label class="form-check-label"  for="{{opzione.nome_domanda_relazione}}">{{opzione.opzione}}</label>
            <input class="form-check-input" id="{{opzione.nome_domanda_relazione}}" type="checkbox">
        </div>
    </ng-template>       
    </div>
</div>
</div>
</div>





