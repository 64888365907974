import { ModalRefService } from '@abp/ng.theme.shared';
import { Component, Input } from '@angular/core';
import { SondaggiDto } from '@proxy';
import { SondaggioDto } from '@proxy/sondaggi-dto';
import { SondaggioService } from '@proxy/sondaggi-service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-eroga-modal',
  standalone: true,
  imports: [],
  templateUrl: './eroga-modal.component.html',
  styleUrl: './eroga-modal.component.scss'
})
export class ErogaModalComponent {

  constructor(private activeModal: NgbActiveModal){}


  onEroga() {
    this.activeModal.close(true)
  }

  onClose() {
    this.activeModal.close(false)
  }



}
