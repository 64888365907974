import { Component, Input } from '@angular/core';
import { SezioneSondaggioDto } from '@proxy/sondaggi-dto';
import { SondaggioService } from '@proxy/sondaggi-service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-dettaglio-sondaggio',
  standalone: true,
  imports: [NgFor],
  templateUrl: './dettaglio-sondaggio.component.html',
  styleUrl: './dettaglio-sondaggio.component.scss'
})
export class DettaglioSondaggioComponent {

  @Input() sondaggio_ID: string
  private sondaggio_subscription!: Subscription
  sezioni_sondaggio: SezioneSondaggioDto[] = []

  constructor(private sondaggioService: SondaggioService, private activeModal: NgbActiveModal){}

  ngOnInit(): void {
    this.getSezioniSondaggio()
  }

  getSezioniSondaggio(){
    this.sondaggio_subscription = this.sondaggioService.getSezioniSondaggioBySondaggioId(this.sondaggio_ID)
    .subscribe({
      next: (response) => {
        this.sezioni_sondaggio = response
      },
      error: (err)=>{
        console.log("Errore nella richiesta delle sezioni sondaggio")
        this.onClose()
      },
      complete: ()=>{
        console.log("Sezioni sondaggio ottenute con successo.")
      }
    })
  }

  onClose() {
    this.activeModal.close()
  }

  ngOnDestroy(): void {
  this.sondaggio_subscription.unsubscribe()
  }

}
