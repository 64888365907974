import { Component, Input } from '@angular/core';
import { FileExportService } from '@proxy/controllers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-download-sezione',
  standalone: true,
  imports: [],
  templateUrl: './download-sezione.component.html',
  styleUrl: './download-sezione.component.scss'
})
export class DownloadSezioneComponent {

  @Input() sondaggioID: string
  @Input() n_sezione: number
  url_download: string = ''
  download_subscription: Subscription

  constructor(private fileExportService: FileExportService){}

  ngOnInit(): void {
    this.download_subscription = this.onEsporta(this.sondaggioID, this.n_sezione)
  }



  onEsporta(sondaggio_id: string , num_sezione: number){
    return this.fileExportService.download(num_sezione,sondaggio_id).subscribe({
      next: (res: Blob) => {
        const data = new Blob([res] , {
          type: 'application/text'}
        )
        this.url_download = URL.createObjectURL(data)
      },
      error: (err) =>{console.log("Impossibile scaricare la risorsa" + sondaggio_id +": " + err)},
      complete: () => {console.log("Download completato")}
    });
  }

  ngOnDestroy(): void {
    this.download_subscription.unsubscribe()
  }

}
