import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IntervistaDto, UpdateIntervistaDto } from '../interviste-dto/models';

@Injectable({
  providedIn: 'root',
})
export class IntervistaService {
  apiName = 'Default';
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IntervistaDto>({
      method: 'GET',
      url: `/api/app/intervista/${id}`,
    },
    { apiName: this.apiName,...config });
  

  update = (input: UpdateIntervistaDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UpdateIntervistaDto>({
      method: 'PUT',
      url: '/api/app/intervista',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
