import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ReferenteAziendaDto } from '../referenti-aziende-dto/models';

@Injectable({
  providedIn: 'root',
})
export class ReferenteAziendaService {
  apiName = 'Default';
  

  delete = (id_referente: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'DELETE',
      responseType: 'text',
      url: '/api/app/referente-azienda',
      params: { id_referente },
    },
    { apiName: this.apiName,...config });
  

  getList = (id_azienda: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReferenteAziendaDto[]>({
      method: 'GET',
      url: '/api/app/referente-azienda',
      params: { id_azienda },
    },
    { apiName: this.apiName,...config });
  

  update = (id_azienda: string, id_utente: string, email_secondaria: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/app/referente-azienda',
      params: { id_azienda, id_utente, email_secondaria },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
