import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AziendeComponent } from './pages/aziende/aziende.component';
import { ProfiloAziendaComponent } from './pages/profilo-azienda/profilo-azienda.component';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { ABP, authGuard } from '@abp/ng.core';
import { SezioneCompletataComponent } from './pages/sezione-completata/sezione-completata.component';
import { IntervistaComponent } from './pages/intervista/intervista.component';
import { AccessoNegatoComponent } from './pages/accesso-negato/accesso-negato.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'account',
    loadChildren: () => import('@abp/ng.account').then(m => m.AccountModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@abp/ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'tenant-management',
    loadChildren: () =>
      import('@abp/ng.tenant-management').then(m => m.TenantManagementModule.forLazy()),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'aziende',
    component: AziendeComponent, canActivate: [authGuard]
  },
  {
    path: 'profilo-azienda',
    component: ProfiloAziendaComponent, canActivate: [authGuard]
  },
  {
    path: 'profilo-azienda/:id',
    component: ProfiloAziendaComponent, canActivate: [authGuard],
  },
  {
    path: 'sezioneCompletata',
    component: SezioneCompletataComponent,
  },
  {
    path: 'interviste/:intervistaID', component: IntervistaComponent,
  } , 
  {
    path: 'accesso_negato', component: AccessoNegatoComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers:[provideRouter(routes, withComponentInputBinding())]
})
export class AppRoutingModule {}
