
 <div class="domanda card col-3 mt-5" *ngIf="attiva">
    <div class="card-body">
            <strong *ngIf="domanda_dettaglio.id_immagine == null; else immagine">{{domanda_dettaglio.testo}}</strong>
            <div class="form-check">
                <input class="form-check-input"  id="{{domanda_dettaglio.nome}}" type="checkbox"  value="si" [(ngModel)]="risposta" (change)="onInput()">
            </div>
            </div>
</div>

<ng-template #immagine>
    <strong>{{domanda_dettaglio.testo}}</strong>
    <img src="\assets\images\img_domande\{{domanda_dettaglio.id_immagine}}.png" class="card-img-top" alt="">
</ng-template>


