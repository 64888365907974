
<div class="domanda card col-6 mt-5" *ngIf="attiva">
    <div class="labelDomanda card-header">
        <strong>
            {{domanda_dettaglio.testo}}
        </strong>
    </div>
    <img src="\assets\images\img_domande\{{domanda_dettaglio.id_immagine}}.png" class="card-img-top" alt="" *ngIf="domanda_dettaglio.id_immagine != null">
    <div class="card-body">
    <div class="form-check">
        <input class="form-check-input" name="{{indice}}" type="radio"  value="vero" [(ngModel)]="risposta" (change)="onInput()">
        <label class="form-check-label">
        Vero
        </label>
    </div>
    <div class="form-check">
        <input class="form-check-input" name="{{indice}}"   type="radio" value="falso" [(ngModel)]="risposta" (change)="onInput()" >
        <label class="form-check-label">
        Falso
        </label>
    </div>
</div>

<div *ngIf="risposta == ''" class="card-footer text-body-secondary">
    *Il campo è obbligatorio.
  </div>
</div>
