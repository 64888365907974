import { RestService, Rest } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AziendaDto, CreaAziendaDto, UpdateAziendaDto } from '../aziende-dto/models';

@Injectable({
  providedIn: 'root',
})
export class AziendaService {
  apiName = 'Default';
  

  create = (input: CreaAziendaDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreaAziendaDto>({
      method: 'POST',
      url: '/api/app/azienda',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id_azienda: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'DELETE',
      responseType: 'text',
      url: '/api/app/azienda',
      params: { id_azienda },
    },
    { apiName: this.apiName,...config });
  

  find = (id_azienda: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AziendaDto>({
      method: 'POST',
      url: '/api/app/azienda/find',
      params: { id_azienda },
    },
    { apiName: this.apiName,...config });
  

  getList = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, AziendaDto[]>({
      method: 'GET',
      url: '/api/app/azienda',
    },
    { apiName: this.apiName,...config });
  

  getPagedAndSortedList = (input: PagedAndSortedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AziendaDto>>({
      method: 'GET',
      url: '/api/app/azienda/paged-and-sorted-list',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  update = (id_azienda: string, input: UpdateAziendaDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/azienda',
      params: { id_azienda },
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
