<form [formGroup]="ModificaAziendaForm" (ngSubmit)="aggiornaAzienda()">
<div class="container pb-3 pt-3" *ngIf="!modificaAbilitata">
    <div class="row">
        <div class="col-2">
            <button type="button" class="btn btn-primary" *abpPermission="'plb.Azienda.Update'" (click)="gestisciModifica()">Modifica</button>
        </div>
    </div>    
</div>

<div class="container pb-3 pt-3" *ngIf="modificaAbilitata">
    <div class="row">
        <div class="col-1">
            <button type="button" class="btn btn-danger" (click)="gestisciModifica()">Annulla</button>
        </div>
        <div class="col-1">
            <button type="submit" class="btn btn-primary">Salva</button>
        </div>
    </div>    
</div>

<div class="container">
<div class="row">
        <div class="col-3">
            <label>Denominazione aziendale</label>
            <ng-container>
            <input class="form-control" type="text" value="{{datiAzienda.denominazione_aziendale}}" readonly *ngIf="!modificaAbilitata; else modDenAziendale">
            <ng-template #modDenAziendale>
                <input class="form-control" type="text" formControlName="denominazioneAziendale" placeholder="{{datiAzienda.denominazione_aziendale}}">
            </ng-template>
            </ng-container>
        </div>
        <div class="col-3">
            <label>Partita IVA</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.partita_iva}}" readonly *ngIf="!modificaAbilitata; else modPI">
                <ng-template #modPI>
                    <input class="form-control" type="text" formControlName="partitaIva" placeholder="{{datiAzienda.partita_iva}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Codice Fiscale</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.codice_fiscale}}" readonly *ngIf="!modificaAbilitata; else modCodFisc">
                <ng-template #modCodFisc>
                    <input class="form-control" type="text" formControlName="codiceFiscale" placeholder="{{datiAzienda.codice_fiscale}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Telefono Principale</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.telefono}}" readonly *ngIf="!modificaAbilitata; else modTel">
                <ng-template #modTel>
                    <input class="form-control" type="text" formControlName="telefono" placeholder="{{datiAzienda.telefono}}">
                </ng-template>
                </ng-container>
        </div>
    </div>
<div class="row">
        <div class="col-3">
            <label>Fax</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.fax}}" readonly *ngIf="!modificaAbilitata; else modFax">
                <ng-template #modFax>
                    <input class="form-control" type="text" formControlName="fax" placeholder="{{datiAzienda.fax}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Email</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.email}}" readonly *ngIf="!modificaAbilitata; else modEmail">
                <ng-template #modEmail>
                    <input class="form-control" type="text" formControlName="email" placeholder="{{datiAzienda.email}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Email secondaria</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.emailsecondaria}}" readonly *ngIf="!modificaAbilitata; else modEmail2">
                <ng-template #modEmail2>
                    <input class="form-control" type="text" formControlName="emailSecondaria" placeholder="{{datiAzienda.emailsecondaria}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Sede legale</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.sede_legale}}" readonly *ngIf="!modificaAbilitata; else modSedeLeg">
                <ng-template #modSedeLeg>
                    <input class="form-control" type="text" formControlName="sedeLegale" placeholder="{{datiAzienda.sede_legale}}">
                </ng-template>
                </ng-container>
        </div>
</div>
<div class="row">
        <div class="col-3">
            <label>Indirizzo</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.indirizzo}}" readonly *ngIf="!modificaAbilitata; else modInd">
                <ng-template #modInd>
                    <input class="form-control" type="text" formControlName="indirizzo" placeholder="{{datiAzienda.indirizzo}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Settore di attività</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.settore_attivita}}" readonly *ngIf="!modificaAbilitata; else modSetAtt">
                <ng-template #modSetAtt>
                    <input class="form-control" type="text" formControlName="settoreAttivita" placeholder="{{datiAzienda.settore_attivita}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Tipologia sede</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.tipologia_sede}}" readonly *ngIf="!modificaAbilitata; else modTipoS">
                <ng-template #modTipoS>
                    <input class="form-control" type="text" formControlName="tipologiaSede" placeholder="{{datiAzienda.tipologia_sede}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Tipo di sede</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.tipo_sede}}" readonly *ngIf="!modificaAbilitata; else modTS">
                <ng-template #modTS>
                    <input class="form-control" type="text" placeholder="{{datiAzienda.tipo_sede}}" formControlName="tipoSede" >
                </ng-template>
                </ng-container>
        </div>
    </div>
<div class="row">
        <div class="col-3">
            <label>Presenza aziendale</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.presenza_azienda}}" readonly *ngIf="!modificaAbilitata; else modPresAz">
                <ng-template #modPresAz>
                    <input class="form-control" type="text" formControlName="presenzaAzienda" placeholder="{{datiAzienda.presenza_azienda}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Numero dipendenti</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.num_dipendenti}}" readonly *ngIf="!modificaAbilitata; else modNumDip">
                <ng-template #modNumDip>
                    <input class="form-control" type="text" formControlName="numeroDipendenti" placeholder="{{datiAzienda.num_dipendenti}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Distribuzione genere</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.distribuzione_genere}}" readonly *ngIf="!modificaAbilitata; else modDistGen">
                <ng-template #modDistGen>
                    <input class="form-control" type="text" formControlName="distribuzioneGenere" placeholder="{{datiAzienda.distribuzione_genere}}">
                </ng-template>
                </ng-container>
        </div>
        <div class="col-3">
            <label>Distribuzione anagrafica</label>
            <ng-container>
                <input class="form-control" type="text" value="{{datiAzienda.distribuzione_anagrafica}}" readonly *ngIf="!modificaAbilitata; else modDistAn">
                <ng-template #modDistAn>
                    <input class="form-control" type="text" formControlName="distribuzioneAnagrafica" placeholder="{{datiAzienda.distribuzione_anagrafica}}">
                </ng-template>
                </ng-container>
        </div>
</div>
</div>
</form>

