import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomandaDto } from '@proxy/domande-dto';
import { CreaRispostaDto } from '@proxy/risposte-dto';
import { RispostaService } from '@proxy/risposte-service';


@Component({
  selector: 'app-domanda-si-no',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
  templateUrl: './domanda-si-no.component.html',
  styleUrl: './domanda-si-no.component.scss'
})
export class DomandaSiNoComponent{
  @Input() domanda_dettaglio!: DomandaDto
  @Input() indice!: number
  @Input() attiva!: boolean
  @Input() testoDomanda!: string
  @Input() risposta!: string
  @Output() emitter = new EventEmitter<string>()


  constructor(){}

  onInput(){
    this.emitter.emit(this.risposta)
  }
  



}
