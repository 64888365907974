import { Component, Input } from '@angular/core';
import { DomandaDto } from '@proxy/domande-dto';
import { IntervistaComponent } from '../intervista.component';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { HtmlParser } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-domanda-titolo',
  standalone: true,
  imports: [IntervistaComponent, NgIf, NgTemplateOutlet, NgSwitch, NgSwitchCase, NgSwitchDefault],
  templateUrl: './domanda-titolo.component.html',
  styleUrl: './domanda-titolo.component.scss'
})
export class DomandaTitoloComponent {
  @Input() domanda_dettaglio!: DomandaDto
  @Input() indice: number
  @Input() attiva!: boolean
  @Input() testoDomanda!: string
  disclaimerQuestionario_1: SafeHtml
  disclaimerQuestionario_2: SafeHtml
  disclaimerQuestionario_3: SafeHtml


  constructor(private http: HttpClient,private sanitizer: DomSanitizer){}

  ngOnInit(): void {
    this.getDisclaimer_1()
    this.getDisclaimer_2()
    this.getDisclaimer_3()
  
  }

  getDisclaimer_1() {
    this.http.get('../../../../assets/IntroQuestionario_1.html', { responseType: 'text' }).subscribe((data) => {
      this.disclaimerQuestionario_1 = this.sanitizer.bypassSecurityTrustHtml(data);
    })
  }

  getDisclaimer_2() {
    this.http.get('../../../../assets/IntroQuestionario_2.html', { responseType: 'text' }).subscribe((data) => {
      this.disclaimerQuestionario_2 = this.sanitizer.bypassSecurityTrustHtml(data);
    })
  }

  getDisclaimer_3() {
    this.http.get('../../../../assets/IntroQuestionario_3.html', { responseType: 'text' }).subscribe((data) => {
      this.disclaimerQuestionario_3 = this.sanitizer.bypassSecurityTrustHtml(data);
    })
  }

  

}
