import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, DoCheck, Input, OnInit, SimpleChanges } from '@angular/core';
import { QuestionarioService } from '@proxy/questionari-service';
import { DomandaDto } from '@proxy/domande-dto';
import { Router, RouterOutlet } from '@angular/router';
import { DomandaSiNoComponent } from "./domanda-si-no/domanda-si-no.component";
import { DomandaTestoLiberoComponent } from "./domanda-testo-libero/domanda-testo-libero.component";
import { DomandaNumericoComponent } from './domanda-numerico/domanda-numerico.component';
import { DomandaTitoloComponent } from './domanda-titolo/domanda-titolo.component';
import { DomandaVeroFalsoComponent } from './domanda-vero-falso/domanda-vero-falso.component';
import { CreaRispostaDto } from '@proxy/risposte-dto';
import { RispostaService } from '@proxy/risposte-service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IntervistaService } from '@proxy/interviste-service';
import { IntervistaDto, UpdateIntervistaDto } from '@proxy/interviste-dto';
import { DomandaSelezionabileComponent } from './domanda-selezionabile/domanda-selezionabile.component';
import { SceltaSingolaComponent } from './scelta-singola/scelta-singola.component';
import { SceltaMultiplaBodyComponent } from './scelta-multipla-body/scelta-multipla-body.component';
import { map, take, timer } from 'rxjs';
import { CountdownService } from 'src/app/servizi/countdown.service';
import { SceltaSingolaTendinaComponent } from './scelta-singola-tendina/scelta-singola-tendina.component';

@Component({
    selector: 'app-intervista',
    standalone: true,
    templateUrl: './intervista.component.html',
    styleUrl: './intervista.component.scss',
    providers: [],
    imports: [NgFor, NgSwitch, NgSwitchCase, ReactiveFormsModule, FormsModule, NgSwitchDefault, RouterOutlet, DomandaSiNoComponent, DomandaTestoLiberoComponent,
      DomandaNumericoComponent, DomandaTitoloComponent, DomandaVeroFalsoComponent, DomandaSelezionabileComponent, SceltaSingolaComponent, SceltaMultiplaBodyComponent,
      SceltaSingolaTendinaComponent]
})
export class IntervistaComponent implements OnInit {
  @Input() intervistaID: string
  intervista: IntervistaDto
  listaDomande: DomandaDto[] = []
  paginaQuestionario: DomandaDto[] = []
  formRipostePagina: FormGroup
  listaRisposte: CreaRispostaDto[] = []
  numeroPagina: number
  primoInit: boolean
  salva: boolean = false
  primo_accesso = true
  risposteSalvate: number = 0
  totaleDomandeIntervista: number = 0

  constructor(private rispostaService: RispostaService,
    private intervistaService: IntervistaService, private fb: FormBuilder, private router: Router, private countDownService: CountdownService){
      this.numeroPagina = 0
      this.primoInit = false
    
  }
  
  
  ngOnInit(): void {
    this.GetIntervista()
  }

  checkFineIntervista(){
    if(this.intervista.pagina_corrente > this.intervista.ultimaPagina)
    {
      this.router.navigate(['sezioneCompletata'])
    }
  }

  GetCountRisposteSalvate(){
    return this.intervista.risposteCorrenti.length
  }

  GetCountDomandeIntervista(){
    return this.intervista.domande.length
  }


   //TESTED
  GetIntervista(){
    this.buildForm()
    this.intervistaService.get(this.intervistaID).subscribe({
      next: (response)=> {
    this.listaDomande = response.domande //popola lista domande con le domande di tutta la sezione
    if(this.totaleDomandeIntervista == 0){
      this.totaleDomandeIntervista = response.domande.length
    }
    this.intervista = response
    this.risposteSalvate = this.intervista.risposteCorrenti.length
    this.checkFineIntervista()
    this.numeroPagina = this.intervista.pagina_corrente
    this.GetPagina(this.intervista.pagina_corrente) //popola paginaQuestionario con la lista di domande della pagina con il numero dato in input
    console.log(this.paginaQuestionario)
    this.buildPaginaForm(this.paginaQuestionario)
    this.ValutaCondizioniDomande()
    if(this.primo_accesso){
      console.log("Primo accesso")
      this.aggiornaNumAccessoIntervista()
      this.primo_accesso = false
      this.GetIntervista()
    } else {
    console.log(this.intervista)
    }
    },
    error: (err)=> {
      console.log("Impossibile accedere all'intervista: " + err)
      this.router.navigate(["/accesso_negato"])
    }
  })
  }

   //TESTED
   //Estrae dall'insieme delle domande della sezione le domande della pagina avente il numero fornito in input
  GetPagina(num_pagina: number){
    this.paginaQuestionario = this.listaDomande.filter((value) =>{
      return value.pagina == num_pagina
    })
    }
  

     //TESTED
     //costruisce li form delle risposte sulla base delle domande in input
  buildPaginaForm(domandePagina: DomandaDto[]){
    domandePagina.forEach((domanda)=>{
        this.AggiungiARisposteFormArray(domanda, this.intervistaID)
      })
  }

  //TESTED
  //Costruisce un form di risposte vuoto
  buildForm(){
    this.formRipostePagina = this.fb.group({
      risposte: this.fb.array<FormGroup>([])
    })
  }

  getValoreRispostaControl(control: AbstractControl, name: string): AbstractControl {
    return control.get(name);
  }


  //TESTED
  get risposteFormArray(){
    return this.formRipostePagina.get('risposte') as FormArray
  }



  //TESTED
  //Aggiunge un Form group risposta al FormArray risposte relativo alla domanda in input
  AggiungiARisposteFormArray(domanda: DomandaDto, intervistaId: string){
    const nuovaRisposta = this.fb.group({
      id_immagine: domanda.id_immagine,
      condizione: domanda.condizione_attivazione,
      testo: domanda.testo,
      tipo: domanda.tipo,
      min: domanda.min,
      max: domanda.max,
      indice_relazione: domanda.indice_relazione,
      attiva: domanda.condizione_attivazione == "null" ? true : false,
      domandaId: domanda.id,
      intervista_id: intervistaId,
      valore_risposta: (domanda.tipo == "Titolo"|| domanda.tipo == "SceltaMultiplaTitolo") ? "titolo" : new FormControl(domanda.tipo == 'SceltaMultipla' ? "non_selezionato": "")
    })
    this.risposteFormArray.push(nuovaRisposta)
  }

  onCambioRiposta(e: Event, indice: number){
    this.risposteFormArray.controls[indice].value.valore_risposta = e
    this.ValutaCondizioniDomande()
  }

  //Tested
  ValutaCondizioniDomande(){
    var risposteDict: {[id: number] : string} = {}   
    this.risposteFormArray.controls.forEach((risposta)=>{
      risposteDict[risposta.value.indice_relazione] = risposta.value.valore_risposta
    })
    this.risposteFormArray.controls.forEach((risposta)=>{
      if(risposta.value.condizione == "null"){
        this.getValoreRispostaControl(risposta, "valore_risposta").setValidators(Validators.required)
        this.getValoreRispostaControl(risposta, "valore_risposta").setValue(risposta.value.valore_risposta)
        this.getValoreRispostaControl(risposta, "attiva").setValue(true)
        this.getValoreRispostaControl(risposta, "valore_risposta").updateValueAndValidity()
      }
      else if(eval(risposta.value.condizione)){
        this.getValoreRispostaControl(risposta, "valore_risposta").setValidators(Validators.required)
        this.getValoreRispostaControl(risposta, "valore_risposta").setValue(risposta.value.valore_risposta)
        this.getValoreRispostaControl(risposta, "attiva").setValue(true)
        this.getValoreRispostaControl(risposta, "valore_risposta").updateValueAndValidity()
      } else {
        this.getValoreRispostaControl(risposta, "valore_risposta").clearValidators()
        this.getValoreRispostaControl(risposta, "attiva").setValue(false)
        this.getValoreRispostaControl(risposta, "valore_risposta").updateValueAndValidity()
      }
    })
  }


  //TESTED
  creaListaRisposte(){
    this.listaRisposte = []
    this.risposteFormArray.value.forEach((control) =>{
      var risposta = new Object as CreaRispostaDto
      risposta.domandaId = control.domandaId
      risposta.intervistaId = control.intervista_id
      risposta.valore_risposta = control.valore_risposta
      this.listaRisposte.push(risposta)
    })
  }


  //TESTED
  salvaRispostePagina(){
    this.rispostaService.createMany(this.listaRisposte).subscribe({
      complete: () => {console.log("Salvataggio risposte completato")},
      error: () => {console.log("Errore nel salvataggio delle risposte")}
    })
  }


  //Tested
  aggiornaPaginaIntervista(){
    var intervistaAggiornata = {} as UpdateIntervistaDto
    intervistaAggiornata.id = this.intervistaID
    intervistaAggiornata.pagina_corrente = this.intervista.pagina_corrente + 1
    intervistaAggiornata.numero_accessi = this.intervista.numero_accessi

    this.intervistaService.update(intervistaAggiornata).subscribe(() => {
      if(this.intervista.pagina_corrente > this.intervista.ultimaPagina)
      {
        this.router.navigate(['sezioneCompletata'])
      } else {
      this.GetIntervista()
      }
    })
  }


  aggiornaNumAccessoIntervista(){
    var intervistaAggiornata = {} as UpdateIntervistaDto
    intervistaAggiornata.id = this.intervistaID
    intervistaAggiornata.pagina_corrente = this.intervista.pagina_corrente
    intervistaAggiornata.numero_accessi = this.intervista.numero_accessi + 1
    console.log(intervistaAggiornata)
    this.intervistaService.update(intervistaAggiornata).subscribe({
      error: (err) => {console.log(err)},
      complete: () => {console.log("Aggiornamento numero di accesso completato.")}
    })
  }


  onClickAvanti(){
    if(this.formRipostePagina.valid)
    {
    this.creaListaRisposte()
    this.salvaRispostePagina()
    this.aggiornaPaginaIntervista()
    }
  }

  

  
}

