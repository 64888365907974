<div class="container">
<div class="row justify-content-between">
<div class="col-10 ">
<h1>Aziende</h1>
</div>
<div class="col-2">
<button  type="button" class="btn btn-primary" *abpPermission="'plb.Azienda.Create'"  (click)="openAggiungiAziendaModal(AggiungiAzienda)">Aggiungi azienda +</button>
</div>
</div>
<div class="row gap-2">
	<div class="col-4 ">
        <input id="filter" class="form-control" type="text" #filter  />
    </div>
	<div class="col-4 ">
		<button  type="button" class="btn btn-primary" (click)="onSearch(filter.value)">Cerca</button>
	</div>
</div>

<table class="table table-striped">
	<thead>
		<tr>
			<th scope="col">Nome</th>
			<th scope="col"></th>
		</tr>
	</thead>
	<tbody>
		@for (azienda of aziende_filtrate; track azienda.id) {
			<tr>
				<td>
					{{ azienda.denominazione_aziendale }}
				</td>
                <td align="end">
                    <button type="button" class="btn btn-link" routerLink="/profilo-azienda/{{azienda.id}}">
                        <i class="bi bi-chevron-right"></i>
                    </button>
                </td>
			</tr>
		}
	</tbody>
</table>
</div>

<ng-template #AggiungiAzienda let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Aggiungi azienda</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
	</div>
	<div class="container" >
	<form [formGroup]="aggiungiAziendaForm" (ngSubmit)="onSubmit()">
		<div class="row">
			<div class="col-4">
					<label class="form-label">Denominazione aziendale*</label>
					<input type="text" class="form-control" formControlName="denominazioneAziendale" >
			</div>
			<div class="col-4">
					<label class="form-label">Partita IVA</label>
					<input type="text" class="form-control" formControlName="partitaIva" >
			</div>
			<div class="col-4">
					<label class="form-label">Email</label>
					<input type="text" class="form-control" formControlName="email" >
			</div>
		</div>
		<div class="row">
			<div class="col-4">
					<label class="form-label">Sede Legale</label>
					<input type="text" class="form-control" formControlName="sedeLegale" >
			</div>
			<div class="col-4">
					<label class="form-label">Settore attività</label>
					<input type="text" class="form-control" formControlName="settoreAttività" >
			</div>
			<div class="col-4">
					<label class="form-label">Numero dipendenti</label>
					<input type="text" class="form-control" formControlName="numeroDipendenti" >
			</div>
		</div>
	</form>
</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-lg btn-outline-primary" (click)="c('Save click')" [disabled]="aggiungiAziendaForm.invalid">Aggiungi</button>
	</div>
</ng-template>

<router-outlet></router-outlet>
