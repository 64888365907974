<div class=" card mt-5" *ngIf="attiva">
  <div class="labelDomanda card-header">
    <strong>
      {{domanda_dettaglio.testo}}
    </strong>
    <img *ngIf="domanda_dettaglio.id_immagine != null" id="{{domanda_dettaglio.id_immagine}}" src="\assets\images\img_domande\{{domanda_dettaglio.id_immagine}}.png" class="card-img-top border-3" alt="...">
  </div>
  <div class="card-body">
    <select class="form-select" [(ngModel)]="risposta" (change)="onInput()">
      <ng-container *ngFor="let opzione of domanda_dettaglio.risposte_possibili">
        <option value="{{opzione.opzione}}"> {{opzione.opzione}} </option>
      </ng-container>
    </select>
  <div *ngIf="risposta == ''" class="card-footer text-body-secondary">
    *Il campo è obbligatorio.
  </div>
</div>
