import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { DocumentoDto, UpdateDocumentoDto } from '../documenti-aziende-dto/models';

@Injectable({
  providedIn: 'root',
})
export class DocumentiAziendaService {
  apiName = 'Default';
  

  delete = (id_documento: string, id_file: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/app/documenti-azienda',
      params: { id_documento, id_file },
    },
    { apiName: this.apiName,...config });
  

  getDocumentBlob = (file_id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      url: '/api/app/documenti-azienda/document-blob',
      params: { file_id },
    },
    { apiName: this.apiName,...config });
  

  getList = (id_azienda: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DocumentoDto[]>({
      method: 'GET',
      url: '/api/app/documenti-azienda',
      params: { id_azienda },
    },
    { apiName: this.apiName,...config });
  

  update = (id_azienda: string, input: UpdateDocumentoDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/documenti-azienda',
      params: { id_azienda },
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
