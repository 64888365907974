import { DOCUMENT, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective, FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { DomandaDto } from '@proxy/domande-dto';

@Component({
  selector: 'app-scelta-multipla-body',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule,NgIf, NgFor],
  templateUrl: './scelta-multipla-body.component.html',
  styleUrl: './scelta-multipla-body.component.scss'
})
export class SceltaMultiplaBodyComponent {

  @Input() domanda_dettaglio!: DomandaDto
  @Input() indice!: number
  @Input() attiva!: boolean


  constructor(@Inject(DOCUMENT) private document: Document){
  }

  ngOnInit(): void {
    if(this.domanda_dettaglio.risposte_possibili != null){
      this.domanda_dettaglio.risposte_possibili.sort((a,b)=> {return (a.indice_ordinamento < b.indice_ordinamento) ? -1 : (a.indice_ordinamento > b.indice_ordinamento) ? 1 : 0})
      }
  }




}




