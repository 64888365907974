import { Component, ElementRef, ViewChild } from '@angular/core';
import { AziendaService } from '@proxy/aziende-service';
import { AziendaDto } from '@proxy/aziende-dto';
import { ListService, PagedResultDto, PermissionService, PermissionDirective } from '@abp/ng.core';
import { ReactiveFormsModule, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { CreaAziendaDto } from '@proxy/aziende-dto';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { UtenteCorrenteService } from '@proxy/current-user-service';
import { ReferenteAziendaService } from '@proxy/referenti-aziende-service';
import { UtenteCorrenteDto } from '@proxy/utente-corr-dto';
import { ReferenteAziendaDto } from '@proxy/referenti-aziende-dto';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-aziende',
  standalone: true,
  imports: [ReactiveFormsModule, MatSlideToggleModule, FormsModule, RouterLink, RouterOutlet, PermissionDirective],
  templateUrl: './aziende.component.html',
  styleUrl: './aziende.component.scss',
  providers: [AziendaService, ListService]
})
export class AziendeComponent {
  private unsubscribe$ = new Subject<void>();
  aziende: AziendaDto[] = []
  aziende_filtrate: AziendaDto[]
  @ViewChild("filter") filterRef:ElementRef
  searchText = new FormControl('', { nonNullable: true });
  aggiungiAziendaForm = new FormGroup({
    denominazioneAziendale: new FormControl('', [Validators.required]),
    partitaIva: new FormControl(''),
    email: new FormControl(''),
    sedeLegale: new FormControl(''),
    settoreAttività: new FormControl(''),
    numeroDipendenti: new FormControl('')

  })


  constructor(public readonly list: ListService, private aziendaService: AziendaService, config: NgbModalConfig,
		private modalService: NgbModal, private route: ActivatedRoute, private permissionService: PermissionService,
    private utenteCorrenteService: UtenteCorrenteService, private refAziendeService: ReferenteAziendaService){
    config.backdrop = 'static';
		config.keyboard = true;
    config.size = 'xl'
    config.centered= true;
  }
  
  ngOnInit() {
    this.getListaAziende();
    this.aziende_filtrate = this.aziende;
    this.route.snapshot.paramMap.get('id');
  }





  onSubmit(){
    var aziendaCreata = new Object() as CreaAziendaDto
    aziendaCreata.denominazione_aziendale = this.aggiungiAziendaForm.value.denominazioneAziendale
    aziendaCreata.email = this.aggiungiAziendaForm.value.email
    aziendaCreata.num_dipendenti = this.aggiungiAziendaForm.value.numeroDipendenti
    aziendaCreata.partita_iva = this.aggiungiAziendaForm.value.partitaIva
    aziendaCreata.sede_legale = this.aggiungiAziendaForm.value.sedeLegale
    aziendaCreata.settore_attivita = this.aggiungiAziendaForm.value.settoreAttività
    this.aziendaService.create(aziendaCreata).subscribe({
      error: (err)=>{
        console.log(err)
      },
      complete: ()=>{
        console.log("Aziende recuperate con successo.")
        this.aggiungiAziendaForm.reset();
        this.getListaAziende();
      }
    })
  }



	openAggiungiAziendaModal(content) {
		this.modalService.open(content).result.then(()=>{
      this.onSubmit();
    })
	}

  onSearch(text: string){
    if(!text){
      this.aziende_filtrate = this.aziende;
      return
    }
    this.aziende_filtrate = this.aziende.filter(
      a => a.denominazione_aziendale.toLowerCase().includes(text.toLowerCase())
    );
    this.filterRef.nativeElement.value = null;
  }


  

  getListaAziende(){
    this.aziendaService.getList().pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      const authReferente = this.permissionService.getGrantedPolicy('plb.Azienda.GetAll')
      if(authReferente){
      this.aziende = response;
      this.aziende_filtrate = this.aziende;
      } else {
        this.getAziendeByReferente();
        this.aziende_filtrate = this.aziende;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAziendeByReferente() {
    var aziendeFull: AziendaDto[] = []
    this.aziende = []
    var utente: UtenteCorrenteDto
    this.utenteCorrenteService.get().subscribe((respn)=>{
      utente = respn
      this.aziendaService.getList().subscribe((resp)=>{
        aziendeFull = resp
        //console.log(this.aziende)
        aziendeFull.forEach(az => {
          this.refAziendeService.getList(az.id).subscribe((res)=>{
            var possibileRef: ReferenteAziendaDto[] = res.filter(x => x.id_utente == utente.id)
            if (possibileRef.length > 0){
              this.aziende.push(az);
            }
          })
        })
      })
    })
  }
  
}
