import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AziendaDto } from '@proxy/aziende-dto';
import { DocumentiAziendaService } from '@proxy/documenti-aziende-service';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { DocumentoDto, UpdateDocumentoDto } from '@proxy/documenti-aziende-dto';
import { TipoDocumento } from '@proxy/enums';
import { TipoDocumentoTransformPipe } from 'src/app/shared/tipo-documento-transform.pipe';
import { PermissionDirective } from '@abp/ng.core';
import { DocumentiExportService, FileExportService } from '@proxy/controllers';
import { DownloadDocumentiModalComponent } from './download-documenti-modal/download-documenti-modal.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-documenti-azienda',
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, ReactiveFormsModule, NgSwitch, NgSwitchCase,
    NgSwitchDefault, DatePipe, TipoDocumentoTransformPipe, PermissionDirective, DownloadDocumentiModalComponent],
  templateUrl: './documenti-azienda.component.html',
  styleUrl: './documenti-azienda.component.scss'
})
export class DocumentiAziendaComponent {

  @Input() datiAzienda: AziendaDto
  @Output() aziendaAggiornata = new EventEmitter()
  private unsubscribe$ = new Subject<void>()
  documenti: DocumentoDto[] = []
  blobFile: number[]
  file_name: string = ''
  statoCaricamentoFile: number = 0



  creaDocumentoForm = new FormGroup({
    nome: new FormControl('',[Validators.required, Validators.minLength(1)]),
    tipo_doc: new FormControl<string>('',[Validators.required]),
    file_blob: new FormControl('',[Validators.required])
  });
  
  ngOnInit(): void {
    this.getDocumenti()
  }

  fileReader = new FileReader()

  constructor(private docAziendaService: DocumentiAziendaService, config: NgbModalConfig,
		private modalService: NgbModal){
      config.backdrop = 'static';
      config.keyboard = true;
      config.size = 'xl'
      config.centered= true;
  }

  getDocumenti() {
    this.docAziendaService.getList(this.datiAzienda.id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (res) => { this.documenti = res },
      error: (err) => { console.debug(err) },
      complete: () => {console.debug("Caricamento lista documenti completato") }

    })
  }

  openAggiungiDocumentoModal(content){
    const ref = this.modalService.open(content)

    ref.result.then((result)=>{
      this.onSubmit();
      this.statoCaricamentoFile = 0
    })

    ref.dismissed.pipe(takeUntil(this.unsubscribe$)).subscribe(()=>{
      this.statoCaricamentoFile = 0
    })
  }

  onFileUploading(event: Event){
    const newFile = (event.target as HTMLInputElement).files[0]
    if (newFile){
      this.statoCaricamentoFile = 1
      this.blobFile = this.fileToNumberArray(newFile)
      this.file_name = newFile.name
      this.statoCaricamentoFile = 2
      
    }
  }

  fileToNumberArray(file: File): number[]{
    var result: number[] = []
    file.arrayBuffer().then((buffer)=>{
      let temp = new Uint8Array(buffer)
      console.log(buffer)
      console.log(temp)
      temp.forEach(el => {
        result.push(el)
      })
    })
    return result
  }

  

  onSubmit(){
    var documento = new Object() as UpdateDocumentoDto
    documento.nome_documento = this.creaDocumentoForm.value.nome
    documento.tipo_documento = this.creaDocumentoForm.value.tipo_doc == "report" ? TipoDocumento.Report : TipoDocumento.Certificazione
    documento.bytes = this.blobFile
    documento.nome_file = this.file_name

    

    this.docAziendaService.update(this.datiAzienda.id, documento).pipe(takeUntil(this.unsubscribe$)).subscribe({
      error: (err) => { console.debug(err) },
      complete: () => {
        console.debug("Aggiornamento documenti completato")
        this.creaDocumentoForm.reset()
        this.getDocumenti()
      }

    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
