import { Component, Input } from '@angular/core';
import { DocumentiExportService } from '@proxy/controllers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-download-documenti-modal',
  standalone: true,
  imports: [],
  templateUrl: './download-documenti-modal.component.html',
  styleUrl: './download-documenti-modal.component.scss'
})
export class DownloadDocumentiModalComponent {
  @Input() nome_documento: string
  @Input() file_id: string

  downloadSub: Subscription
  url_download: string = ""

  constructor(private docExportService: DocumentiExportService ){}

  ngOnInit(): void {
    this.downloadSub = this.downloadDocumento(this.file_id)
  }


  downloadDocumento(file_id: string){
    return this.docExportService.download(file_id).subscribe({
      next: (res: Blob) => {
        console.log(res)
        const data = new Blob([res] , {
          type: 'application/blob'}
      )
        this.url_download = URL.createObjectURL(data)
      },
      error: (err) =>{console.log("Impossibile scaricare la risorsa" + file_id +": " + err)},
      complete: () => {console.log("Download completato");}
    });
  }

  ngOnDestroy(): void {
    this.downloadSub.unsubscribe()
  }

}
