<div class="container-fluid">
    <button type="button" class="btn btn-light" routerLink="/aziende">
    <i class="bi bi-chevron-left"></i>
    Indietro
</button>
<div class="container pb-3">
<h1 class="titolo">{{azienda.denominazione_aziendale | uppercase}}</h1>
</div>

<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	<li [ngbNavItem]="1">
		<button ngbNavLink>Anagrafica azienda</button>
		<ng-template ngbNavContent>
			<app-anagrafica-azienda [datiAzienda]="azienda" (aziendaAggiornata)="aziendaAggiornataDaAnagrafica($event)"></app-anagrafica-azienda>
		</ng-template>
	</li>
	<li *abpPermission="'plb.Referente.Update'" [ngbNavItem]="2">
		<button ngbNavLink>Referenti</button>
		<ng-template ngbNavContent>
            <app-referenti-azienda [datiAzienda]="azienda" (aziendaAggiornata)="aziendaAggiornataDaAnagrafica($event)"></app-referenti-azienda>
		</ng-template>
	</li>
    <li [ngbNavItem]="3">
		<button ngbNavLink>Sondaggi</button>
		<ng-template ngbNavContent>
			<app-sondaggi-azienda [datiAzienda]="azienda" (aziendaAggiornata)="aziendaAggiornataDaAnagrafica($event)"></app-sondaggi-azienda>
		</ng-template>
	</li>
    <li [ngbNavItem]="4">
		<button ngbNavLink>Documenti</button>
		<ng-template ngbNavContent>
			<app-documenti-azienda [datiAzienda]="azienda" (aziendaAggiornata)="aziendaAggiornataDaAnagrafica($event)"></app-documenti-azienda>
		</ng-template>
	</li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

</div>

<router-outlet></router-outlet>

