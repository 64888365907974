<div [hidden]="!attiva" >
  <ng-container [ngSwitch]="domanda_dettaglio.nome">
    <div *ngSwitchCase="'PLB QUESTIONARIO'" [innerHtml]="disclaimerQuestionario_1"></div>
    <div *ngSwitchCase="'PLB QUESTIONARIO 2'" [innerHtml]="disclaimerQuestionario_2"></div>
    <div *ngSwitchCase="'PLB QUESTIONARIO 3'" [innerHtml]="disclaimerQuestionario_3"></div>
    <div class="container mt-5" *ngSwitchDefault>
      <h2>{{domanda_dettaglio.testo}}</h2>
      <img src="\assets\images\img_domande\{{domanda_dettaglio.id_immagine}}.png" class="card-img-top" alt="" *ngIf="domanda_dettaglio.id_immagine != null">
    </div>
  </ng-container>
</div>
