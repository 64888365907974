import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';

@NgModule({
  declarations: [],
  imports: [
    CoreModule,
    ThemeSharedModule,
    NgbDropdownModule,
    NgxValidateCoreModule
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    NgbDropdownModule,
    NgxValidateCoreModule
  ],
  providers: []
})
export class SharedModule {
  
}

export const StrongPasswordRegx: RegExp =
/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;

export enum StatoSondaggio {
  CREATO = "CREATO",
  IN_CORSO = "IN CORSO",
  SCADUTO = "SCADUTO",
  COMPLETATO = "COMPLETATO"
}

export enum StatoIntervista {
  CREATA = 0,
  IN_CORSO = 1,
  SCADUTA = 2,
  COMPLETATA = 3,
  BLOCCATA = 4
}
