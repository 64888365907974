import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomandaDto } from '@proxy/domande-dto';

@Component({
  selector: 'app-domanda-vero-falso',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
  templateUrl: './domanda-vero-falso.component.html',
  styleUrl: './domanda-vero-falso.component.scss'
})
export class DomandaVeroFalsoComponent {
  @Input() domanda_dettaglio!: DomandaDto
  @Input() indice: number
  @Input() attiva!: boolean
  @Input() risposta!: string
  @Output() emitter = new EventEmitter<string>()


  constructor(){}

  onInput(){
    this.emitter.emit(this.risposta)
  }
  





}
