import { Injectable } from '@angular/core';
import { map, Observable, take, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountdownService {
  tempoRimasto: number = 0

  constructor() { }

  setTempoRimasto(input: number){
    this.tempoRimasto = input
  }

  start(): Observable<number> {
    return timer(0, 1000).pipe(
      take(this.tempoRimasto/1000),
      map(tempoPassato => this.tempoRimasto - tempoPassato)
    )
  }

  getTempoRimasto(){
    return this.tempoRimasto;
  }


}
