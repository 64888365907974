
import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomandaDto } from '@proxy/domande-dto';
import { OpzioneDto } from '@proxy/opzioni-dto';

@Component({
  selector: 'app-scelta-singola',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule,NgFor, NgIf],
  templateUrl: './scelta-singola.component.html',
  styleUrl: './scelta-singola.component.scss'
})
export class SceltaSingolaComponent {
  @Input() domanda_dettaglio!: DomandaDto
  @Input() indice!: number
  @Input() attiva!: boolean
  @Input() testoDomanda!: string
  @Input() risposta!: string
  @Output() emitter = new EventEmitter<string>()


  constructor(){
  }

  ngOnInit(): void {
    console.log(this.domanda_dettaglio)
    if (this.domanda_dettaglio.risposte_possibili != null) {
      this.domanda_dettaglio.risposte_possibili.sort((a, b) => { return (a.indice_ordinamento < b.indice_ordinamento) ? -1 : (a.indice_ordinamento > b.indice_ordinamento) ? 1 : 0 })
    }
    
  }

  onInput(){
    this.emitter.emit(this.risposta)
  }

}
