import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { DomandaDto } from '@proxy/domande-dto';

@Component({
  selector: 'app-domanda-selezionabile',
  standalone: true,
  imports: [NgIf],
  templateUrl: './domanda-selezionabile.component.html',
  styleUrl: './domanda-selezionabile.component.scss'
})
export class DomandaSelezionabileComponent {
  @Input() domanda_dettaglio!: DomandaDto
  @Input() indice!: number
  @Input() attiva!: boolean
  @Input() risposta!: string
  @Output() emitter = new EventEmitter<string>()
  enhancer!: any

  constructor(private renderer: Renderer2){}

  ngOnInit(): void {
    this.enhancer = document.getElementById(`${this.domanda_dettaglio.id}`)
    this.enhancer.addEventListener('click',()=>{
      this.risposta = this.risposta != this.domanda_dettaglio.testo ? this.domanda_dettaglio.testo :'no'
      if(this.risposta != 'no'){
        this.renderer.addClass(this.enhancer, 'border-primary')
      } else {
        this.renderer.removeClass(this.enhancer, 'border-primary')
      }
      console.log(this.risposta)
      this.emitter.emit(this.risposta)
    })


    }


  onInput(){
    if(this.risposta != 'si'){
    this.emitter.emit('no')
    } else {
    this.emitter.emit(this.risposta)

    }
  }
}
