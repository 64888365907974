import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoDocumentoTransform',
  standalone: true
})
export class TipoDocumentoTransformPipe implements PipeTransform {

  transform(value: number): string {
    return value == 0 ? 'Report' : 'Certificazione'
  }

}
