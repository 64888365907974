import { RestService, Rest } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreaSondaggioDto, SezioneSondaggioDto, SondaggioDto } from '../sondaggi-dto/models';

@Injectable({
  providedIn: 'root',
})
export class SondaggioService {
  apiName = 'Default';
  

  create = (input: CreaSondaggioDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreaSondaggioDto>({
      method: 'POST',
      url: '/api/app/sondaggio',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (sondaggio_id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'DELETE',
      responseType: 'text',
      url: '/api/app/sondaggio',
      params: { sondaggio_id },
    },
    { apiName: this.apiName,...config });
  

  erogaIntervisteSondaggioBySondaggio_id = (sondaggio_id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/sondaggio/eroga-interviste-sondaggio',
      params: { sondaggio_id },
    },
    { apiName: this.apiName,...config });
  

  getDatiExp = (sezione: number, sondaggio_id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      url: '/api/app/sondaggio/dati-exp',
      params: { sezione, sondaggio_id },
    },
    { apiName: this.apiName,...config });
  

  getList = (aziendaID: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SondaggioDto[]>({
      method: 'GET',
      url: '/api/app/sondaggio',
      params: { aziendaID },
    },
    { apiName: this.apiName,...config });
  

  getPagedAndSortedList = (input: PagedAndSortedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<SondaggioDto>>({
      method: 'GET',
      url: '/api/app/sondaggio/paged-and-sorted-list',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getSezioniSondaggioBySondaggioId = (sondaggioId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SezioneSondaggioDto[]>({
      method: 'GET',
      url: `/api/app/sondaggio/sezioni-sondaggio/${sondaggioId}`,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
