<div class="container">
    <div class="creaSondaggio">
        <button type="button" class="btn btn-primary" *abpPermission="'plb.Sondaggio.Create'" (click)="openAggiungiSondaggioModal(CreaSondaggio)">Crea sondaggio +</button>
    </div>
	<div class="container" *ngIf="sondaggi.length > 0; else noSondaggi">
    <table class="table table-striped">
		<thead>
			<tr>
				<th>Nome sondaggio</th>
				<th>Stato</th>
				<th>Percentuale di completamento</th>
				<th>Data media di inizio</th>
				<th>Tempo medio di compilazione</th>
				<th>Data di inizio</th>
				<th>Data di scadenza</th>
				<th></th>
				<th *abpPermission="'plb.Sondaggio.Send'"></th>
				<th *abpPermission="'plb.Sondaggio.Download'"></th>
			</tr>
		</thead>
        <tbody *ngFor="let sondaggio of sondaggi">
            <tr (click)="onSelectSondaggio(sondaggio)">
                <td>{{sondaggio.nome}}</td>
				<td>{{getStatoSondaggio(sondaggio)}}</td>
				<td>{{sondaggio.stato_di_completamento | percent}}</td>
				<ng-container *ngIf="sondaggio.data_media_inizio_compilazione != null;else NonDisponibile">
				<td>{{sondaggio.data_media_inizio_compilazione| date:'mediumDate'}}</td>
				</ng-container>
				<ng-container *ngIf="sondaggio.tempo_medio_compilazione != null;else NonDisponibile">
					<td>{{sondaggio.tempo_medio_compilazione | slice:0:8}}</td>
				</ng-container>
                <td>{{sondaggio.data_di_inizio | date:'mediumDate'}}</td>
                <td>{{sondaggio.data_di_scadenza | date:'mediumDate'}}</td>
				<td>
					<button type="button" class="btn btn-outline-primary" id="dettaglio" (click)="onClickonDettaglio(sondaggio.id)">
						Dettaglio
					</button>
				</td>
				<td>
					<button type="button" class="btn btn-outline-primary" *abpPermission="'plb.Sondaggio.Create'" id="eroga" (click)="onClickonEroga(sondaggio)">
						Eroga
					</button>
				</td>
				<td>
					<button type="button" class="btn btn-outline-primary" *abpPermission="'plb.Sondaggio.Create'" id="scarica" (click)="onOpenMenuEsporta(menuEsportazione)" >
						Scarica dati
					</button>
				</td>
            </tr>
        </tbody>
    </table>
</div>
</div>

	<ng-template #noSondaggi>
		<div>
			<p>Nessun Sondaggio da mostrare.</p>
		</div>    
	</ng-template>

    
<ng-template #CreaSondaggio let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Crea Sondaggio</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
	</div>
	<div class="container" >
	<form id="creaSondaggioForm" [formGroup]="creaSondaggioForm" (ngSubmit)="onSubmit()" >
		<div class="row">
			<div class="col-10">
					<label class="form-label">Nome*</label>
					<input type="text" class="form-control" formControlName="nome"  >
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Data di inizio*</label>
					<input type="date" class="form-control" formControlName="data_di_inizio"  >
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Data di scadenza*</label>
					<input type="date" class="form-control" formControlName="data_di_scadenza" >
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label for="quest">Questionario*</label>
					<select id="quest" class="form-select" aria-label="Default select example" formControlName="questionario">
						<option *ngFor="let quest of listaQuestionari" [ngValue]="quest.id">{{quest.nome_questionario}}</option>
					</select>
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Lista intervistati*</label>
					<input type="file" class="form-control" (change)= "onFileUploading($event)" formControlName="lista_intervistati"  >
					<div [ngSwitch]="fileReader.readyState">
						<p *ngSwitchCase="0">Caricare file</p>
						<p *ngSwitchCase="1">Caricamento in corso...</p>
						<p *ngSwitchCase="2">Caricamento completato</p>
						<p *ngSwitchDefault=""></p>
					</div>	
			</div>
		</div>
	</form>
</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-lg btn-outline-primary" (click)="c('Save click')" [disabled]="creaSondaggioForm.invalid"  >Crea</button>
	</div>
</ng-template>

<ng-template #NonDisponibile>
	<td>Non disponibile al momento</td>
</ng-template>

<ng-template #menuEsportazione let-c="close" let-d="dismiss">
	<div class="container">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Lista Sezioni</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
	</div>
	<div class="vstack gap-4 p-5">
		<ng-container *ngFor="let sezione of sondaggioSelezionato.sezioni">
			<app-download-sezione [sondaggioID]="sondaggioSelezionato.id" [n_sezione]="sezione"></app-download-sezione>
		</ng-container>		
	</div>
</div>
</ng-template>

<ng-template #erogaModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Eroga Sondaggio</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
	</div>
	<div class="modal-body">
		Vuoi Davvero erogare il sondaggio agli intervistati?	
	</div>
	<div class="modal-footer">
		<button type="button" class="btn-primary" (click)="onEroga()"></button>	
	</div>
</ng-template>

