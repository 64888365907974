import { IdentityUserCreateDto, IdentityUserDto, IdentityUserService } from '@abp/ng.identity/proxy';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import { AziendaDto, CreaAziendaDto, UpdateAziendaDto } from '@proxy/aziende-dto';
import { NgFor, NgIf, Location } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { AziendaService } from '@proxy/aziende-service';
import { ReferenteAziendaService } from '@proxy/referenti-aziende-service';
import { ReferenteAziendaDto } from '@proxy/referenti-aziende-dto';
import { PermissionDirective } from '@abp/ng.core';
import { StrongPasswordRegx } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-referenti-azienda',
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, ReactiveFormsModule, PermissionDirective],
  templateUrl: './referenti-azienda.component.html',
  styleUrl: './referenti-azienda.component.scss'
})
export class ReferentiAziendaComponent {

  @Input() datiAzienda: AziendaDto
  @Output() aziendaAggiornata = new EventEmitter()
  referentiAziendali: IdentityUserDto[] = []
  referentiAziendaliInfo: ReferenteAziendaDto[] = []
  @ViewChild('utenteNonEsiste', { read: TemplateRef }) utenteNonEsiste:TemplateRef<any>;
  @ViewChild('utenteGiàReferente', { read: TemplateRef }) utenteGiàReferente:TemplateRef<any>;
  @ViewChild('listaReferenti', { read: TemplateRef }) listaReferenti:TemplateRef<any>;


  isAggiungiReferenteOpen: boolean = false

  cercaReferenteForm = new FormGroup({
    emailReferente: new FormControl('')
  });
  creaReferenteForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.minLength(5)]),
    nome: new FormControl('', [Validators.required]),
    cognome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    email_secondaria: new FormControl(''),
    password: new FormControl('', [Validators.required, Validators.pattern(StrongPasswordRegx)]),
    telefono: new FormControl('', [Validators.required])
  });

  constructor(private userService: IdentityUserService, config: NgbModalConfig,
		private modalService: NgbModal, private referentiAziendaService: ReferenteAziendaService){
      config.backdrop = 'static';
      config.keyboard = true;
      config.size = 'xl'
      config.centered= true;
  }

  ngOnInit(): void {
    this.aggiornaListaReferenti()
  }

  getEmailSecondariaReferente(referente_id: string){
    var result =  this.referentiAziendaliInfo.filter(x => {if (x.id_utente == referente_id) {return x}})
    return result.length > 0 ? result[0].mail_secondaria : null
  }

  aggiornaListaReferenti()
  {
    this.referentiAziendali = []
    this.referentiAziendaService.getList(this.datiAzienda.id).subscribe((response)=>{
      this.referentiAziendaliInfo = response
      this.getReferentiAziendali(response)
      //this.listaReferenti.elementRef.nativeElement.refresh()
    })  
  }

  onChiudiModale(){
    this.modalService.dismissAll()
    this.cercaReferenteForm.reset()
  }


  openAggiungiReferenteModal(content){
    this.modalService.open(content).result.then((result)=>{
      this.cercaRefonSubmit();
    })
  }

  openCreaReferenteModal(content){
    this.modalService.open(content).result.then((result)=>{
      this.aggiungiRefonSubmit();
      this.modalService.dismissAll()
    })
  }

  checkFormUtenteEsistente(email: string){
    var utenti_esistenti = this.referentiAziendali.map((a)=>{return a.email})
    console.log(utenti_esistenti)
    if (utenti_esistenti.includes(email)){
      console.log("Utente esistente")
      return true
    } else {
      console.log("Utente non esistente")
      return false
    }
  }

  checkUtenteEsistente(email: string){
    if(this.referentiAziendali.length == 0){
      console.log("utenti assenti per l'azienda")
      return false
    }
    var utenti_esistenti = this.referentiAziendali.map((a)=>{return a.email})
    if (utenti_esistenti.includes(email)){
      return true
    } else {
      return false
    }
  }

  checkPassword(password: string){
    if(password.match(StrongPasswordRegx)){
      return true
    } else {
      return false
    }
  }



  aggiungiRefonSubmit(){
    var nuovoReferente = new Object() as IdentityUserCreateDto
    var email_secondaria = this.creaReferenteForm.value.email_secondaria != '' ? this.creaReferenteForm.value.email_secondaria : null
    nuovoReferente.name = this.creaReferenteForm.value.nome
    nuovoReferente.surname = this.creaReferenteForm.value.cognome
    nuovoReferente.email = this.checkFormUtenteEsistente(this.creaReferenteForm.value.email) ? undefined : this.creaReferenteForm.value.email
    nuovoReferente.password = this.creaReferenteForm.value.password
    nuovoReferente.phoneNumber = this.creaReferenteForm.value.telefono
    nuovoReferente.userName = this.creaReferenteForm.value.username
    nuovoReferente.isActive = true
    nuovoReferente.lockoutEnabled = false
    nuovoReferente.roleNames = ["referente"]
    this.userService.create(nuovoReferente).subscribe((response)=>{
      this.referentiAziendaService.update(this.datiAzienda.id, response.id, email_secondaria).subscribe(()=>{
        this.aziendaAggiornata.emit();
        this.aggiornaListaReferenti()
      })
    });

  }

  cercaRefonSubmit(){
    this.userService.findByEmail(this.cercaReferenteForm.value.emailReferente).subscribe(((response)=>{
      if(response == null)
      {
        console.log("utente nullo o non esistente")
        this.modalService.open(this.utenteNonEsiste)
      } 
      else 
      {
        console.log("utente esistente")
        var controllo = false
        this.referentiAziendali.forEach(el=>{
          if(el.id == response.id)
          {
            controllo = true
          }
        })
        if(controllo) 
        {
          console.log("utente già referente")
          this.modalService.open(this.utenteGiàReferente)
        }
        else 
        {  
          this.referentiAziendaService.update(this.datiAzienda.id, response.id, null).subscribe((response)=>{
            this.aziendaAggiornata.emit();
            this.aggiornaListaReferenti()
          })
        }
      }
    }))
  }


getReferentiAziendali(content: ReferenteAziendaDto[]){
  if(content.length > 0){
  content.forEach(referente => {
    this.userService.get(referente.id_utente).subscribe((response)=>{
    this.referentiAziendali.push(response);
    })
  });
  } else {
    console.log("Non ci sono referenti")
  }
}

}