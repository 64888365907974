<div class=" card mt-5" *ngIf="attiva">
    <div class="labelDomanda card-header">
    <strong>
    {{domanda_dettaglio.testo}}
    </strong>
    <img *ngIf="domanda_dettaglio.id_immagine != null"  id="{{domanda_dettaglio.id_immagine}}" src="\assets\images\img_domande\{{domanda_dettaglio.id_immagine}}.png" class="card-img-top border-3" alt="..." >
    </div>
    <div class="card-body">
    <div class="form-check" *ngFor="let opzione of domanda_dettaglio.risposte_possibili" >
        <input class="form-check-input" type="radio" name="{{indice}}"  value="{{opzione.opzione}}" [(ngModel)]="risposta" (change)="onInput()" >
        <label class="form-check-label">
        {{opzione.opzione}}
        </label>
    </div>
</div>
<div *ngIf="risposta == ''" class="card-footer text-body-secondary">
    *Il campo è obbligatorio.
  </div>
</div>
