<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Attenzione</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="modal-body">
    Vuoi Davvero erogare il sondaggio agli intervistati?	
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onEroga()">Eroga</button>	
</div>
