import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomandaDto } from '@proxy/domande-dto';
import { max } from 'rxjs';

@Component({
  selector: 'app-domanda-numerico',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgFor, NgIf],
  templateUrl: './domanda-numerico.component.html',
  styleUrl: './domanda-numerico.component.scss'
})
export class DomandaNumericoComponent {
  @Input() domanda_dettaglio!: DomandaDto
  @Input() indice!: number
  @Input() attiva!: boolean
  @Input() risposta!: string
  @Output() emitter = new EventEmitter<string>()
  range: number[] = []


  constructor(){}



  ngOnInit(): void {
    this.setRange(this.domanda_dettaglio.max, this.domanda_dettaglio.min )
  }

  onInput(){
    this.emitter.emit(this.risposta)
  }

  setRange(maxValue: number, minValue: number){
  var count = maxValue - minValue
  while(count >= 0){
    this.range.push(count)
    count = count - 1
  }
  this.range = this.range.reverse()
  }



  
}