import { Component, Input } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { AnagraficaAziendaComponent } from './anagrafica-azienda/anagrafica-azienda.component';
import { AziendaService } from '@proxy/aziende-service';
import { AziendaDto } from '@proxy/aziende-dto';
import { ReferentiAziendaComponent } from './referenti-azienda/referenti-azienda.component';
import { SondaggiAziendaComponent } from './sondaggi-azienda/sondaggi-azienda.component';
import { DocumentiAziendaComponent } from './documenti-azienda/documenti-azienda.component';
import { IntervistaComponent } from '../intervista/intervista.component';
import { Subject, takeUntil } from 'rxjs';



@Component({
  selector: 'app-profilo-azienda',
  standalone: true,
  imports: [ MatTabsModule, RouterLink, RouterOutlet, NgbNavModule, SharedModule, AnagraficaAziendaComponent, ReferentiAziendaComponent,
  SondaggiAziendaComponent, DocumentiAziendaComponent, IntervistaComponent],
  templateUrl: './profilo-azienda.component.html',
  styleUrl: './profilo-azienda.component.scss'
})
export class ProfiloAziendaComponent {
  @Input() id: string
  private unsubscribe$ = new Subject<void>();
  azienda = new Object() as AziendaDto
  active=1

  constructor(private aziendeService: AziendaService){
  }

  ngOnInit(): void {
    this.GetAzienda()
  }

  GetAzienda() {
    this.aziendeService.find(this.id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => { this.azienda = response; },
      error: (err) => { console.debug(err) },
      complete: ()=> {console.debug("Caricamento azienda completato")}
    }) 
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  aziendaAggiornataDaAnagrafica(event: Event) {
    this.GetAzienda();
  }

}
