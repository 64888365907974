import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomandaDto } from '@proxy/domande-dto';
import { CreaRispostaDto } from '@proxy/risposte-dto';

@Component({
  selector: 'app-domanda-testo-libero',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
  templateUrl: './domanda-testo-libero.component.html',
  styleUrl: './domanda-testo-libero.component.scss'
})
export class DomandaTestoLiberoComponent {
  @Input() domanda_dettaglio:  DomandaDto
  @Input() indice: number
  @Input() attiva!: boolean
  @Input() risposta!: string
  @Output() emitter = new EventEmitter<string>()


  constructor(){}

  onInput(){
    this.emitter.emit(this.risposta)
  }





  



}
