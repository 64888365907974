import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { UtenteCorrenteDto } from '../utente-corr-dto/models';

@Injectable({
  providedIn: 'root',
})
export class UtenteCorrenteService {
  apiName = 'Default';
  

  get = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, UtenteCorrenteDto>({
      method: 'GET',
      url: '/api/app/utente-corrente',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
