<div class="card mt-5 col-6" *ngIf="attiva">
<div class="labelDomanda card-header">
    <strong>
        {{domanda_dettaglio.testo}}
        </strong>
</div>
<div class="card-body">
<select class="form-select" [(ngModel)]="risposta" (change)="onInput()">
<ng-container *ngFor="let el of range">    
    <option value="{{el}}">{{el}}</option>
</ng-container>
</select>
</div>
<div *ngIf="risposta == ''" class="card-footer text-body-secondary">
    *Il campo è obbligatorio.
  </div>
</div>
