<div class="container">
    <div class="creaDocumento">
        <button type="button" class="btn btn-primary" *abpPermission="'plb.Documento.Update'" (click)="openAggiungiDocumentoModal(CreaDocumento)">Aggiungi documento +</button>
    </div>
	<div class="container" *ngIf="documenti.length > 0;else noDocumenti">
    <table class="table table-striped">
		<thead>
			<tr>
				<th>Nome</th>
				<th>Tipologia</th>
				<th>Data di creazione</th>
				<th *abpPermission="'plb.Documento.Download'"></th>
			</tr>
		</thead>
        <tbody *ngFor="let documento of documenti">
            <tr>
                <td>{{documento.nome_documento}}</td>
                <td>{{documento.tipo_documento | tipoDocumentoTransform}}</td>
                <td>{{documento.data_creazione | date:'mediumDate'}}</td>
                <td align="end" *abpPermission="'plb.Documento.Download'">
                    <app-download-documenti-modal [nome_documento]="documento.nome_file" [file_id]="documento.file_id"></app-download-documenti-modal>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</div>
	<ng-template #noDocumenti>
		<div>
			<p>Nessun Documento da mostrare.</p>
		</div>    
	</ng-template>

    
<ng-template #CreaDocumento let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Aggiungi documento</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
	</div>
	<div class="container" >
	<form id="creaDocumentoForm" [formGroup]="creaDocumentoForm" (ngSubmit)="onSubmit()" >
		<div class="row">
			<div class="col-10">
					<label class="form-label">Nome*</label>
					<input type="text" class="form-control" formControlName="nome"  >
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">Tipo documento*</label>
					<select class="form-select" aria-label="Default select example" formControlName="tipo_doc">
						<option value="report">Report</option>
						<option value="certificazione">Certificazione</option>
					</select>
			</div>
		</div>
        <div class="row">
			<div class="col-10">
					<label class="form-label">File*</label>
					<input type="file" class="form-control" (change)= "onFileUploading($event)" formControlName="file_blob" id="file_upload">
					<div [ngSwitch]="statoCaricamentoFile">
						<p *ngSwitchCase="0">Caricare file</p>
						<p *ngSwitchCase="1">Caricamento in corso...</p>
						<p *ngSwitchCase="2">Caricamento completato</p>
						<p *ngSwitchDefault=""></p>
					</div>	
			</div>
		</div>
	</form>
</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-lg btn-outline-primary" (click)="c('Save click')" [disabled]="creaDocumentoForm.invalid"  >Crea</button>
	</div>
</ng-template>
