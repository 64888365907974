import { Component } from '@angular/core';

@Component({
  selector: 'app-sezione-completata',
  standalone: true,
  imports: [],
  templateUrl: './sezione-completata.component.html',
  styleUrl: './sezione-completata.component.scss'
})
export class SezioneCompletataComponent {

}
