<div class="container">
    <h4 class="mb-3">Pagina {{numeroPagina}}</h4>
    <p align="end">Risposte correnti: {{risposteSalvate}} / {{totaleDomandeIntervista}}</p>

    <form [formGroup]="formRipostePagina">
    <div class="container" *ngFor="let domanda of paginaQuestionario;let controllo of risposteFormArray.controls; index as i">
        <ng-container [ngSwitch]="domanda.tipo">
            <app-domanda-titolo *ngSwitchCase="'Titolo'"
            [domanda_dettaglio]="domanda"
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva">
            </app-domanda-titolo>
            <app-scelta-multipla-body *ngSwitchCase="'SceltaMultiplaTitolo'"
            [domanda_dettaglio]="domanda"
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva">
            </app-scelta-multipla-body>
            <app-domanda-si-no *ngSwitchCase="'SiNo'"
            [domanda_dettaglio]="domanda"
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva" 
            [testoDomanda]="risposteFormArray.controls[i].value.testo"
            [(risposta)]="risposteFormArray.controls[i].value.valore_risposta" 
            (emitter)="onCambioRiposta($event,i)">
            </app-domanda-si-no>
            <app-domanda-testo-libero *ngSwitchCase="'TestoLibero'"
            [domanda_dettaglio]="domanda" 
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva" 
            [(risposta)]="risposteFormArray.controls[i].value.valore_risposta" 
            (emitter)="onCambioRiposta($event,i)">
            </app-domanda-testo-libero>
            <app-domanda-vero-falso  *ngSwitchCase="'VeroFalso'"
            [domanda_dettaglio]="domanda"
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva" 
            [(risposta)]="risposteFormArray.controls[i].value.valore_risposta" 
            (emitter)="onCambioRiposta($event,i)">
            </app-domanda-vero-falso>
            <app-scelta-singola  *ngSwitchCase="'SceltaSingola'"
            [domanda_dettaglio]="domanda"
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva" 
            [opzioni]="domanda.risposte_possibili"
            [(risposta)]="risposteFormArray.controls[i].value.valore_risposta" 
            (emitter)="onCambioRiposta($event,i)">
            </app-scelta-singola>
            <app-domanda-numerico  *ngSwitchCase="'Numerico'"
            [domanda_dettaglio]="domanda"
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva"
            [max]="domanda.max" 
            [min]="domanda.min"
            [testoDomanda]="risposteFormArray.controls[i].value.testo" 
            [(risposta)]="risposteFormArray.controls[i].value.valore_risposta" 
            (emitter)="onCambioRiposta($event,i)">
            </app-domanda-numerico>
            <app-domanda-selezionabile *ngSwitchCase="'SceltaMultipla'"
            [domanda_dettaglio]="domanda"
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva"
            [(risposta)]="risposteFormArray.controls[i].value.valore_risposta" 
            [opzioni]="risposteFormArray.controls"
            (emitter)="onCambioRiposta($event,i)"
            hidden >
            </app-domanda-selezionabile>
            <app-scelta-singola-tendina  *ngSwitchCase="'SceltaSingolaTendina'"
            [domanda_dettaglio]="domanda"
            [indice]="i" 
            [attiva]="risposteFormArray.controls[i].value.attiva" 
            [opzioni]="domanda.risposte_possibili"
            [(risposta)]="risposteFormArray.controls[i].value.valore_risposta" 
            (emitter)="onCambioRiposta($event,i)">
            </app-scelta-singola-tendina>
        </ng-container>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button  type="button" class="btn btn-primary mt-5 mb-2 justify-content-end" (click)="onClickAvanti()" [disabled]="!formRipostePagina.valid" >Salva e procedi</button>
</div>
    </form>
</div>
