import { Component, EventEmitter, Output } from '@angular/core';
import {MatGridListModule} from '@angular/material/grid-list';
import { Input } from '@angular/core';import { AziendaDto, CreaAziendaDto, UpdateAziendaDto } from '@proxy/aziende-dto';
import { AziendaService } from '@proxy/aziende-service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { ProfiloAziendaComponent } from '../profilo-azienda.component';
import { PermissionService, PermissionDirective } from '@abp/ng.core';

@Component({
  selector: 'app-anagrafica-azienda',
  standalone: true,
  imports: [MatGridListModule, NgIf, ReactiveFormsModule, FormsModule, ProfiloAziendaComponent, PermissionDirective],
  templateUrl: './anagrafica-azienda.component.html',
  styleUrl: './anagrafica-azienda.component.scss'
})
export class AnagraficaAziendaComponent {
@Input() datiAzienda: AziendaDto
@Output() aziendaAggiornata = new EventEmitter()

ModificaAziendaForm = new FormGroup({
  denominazioneAziendale: new FormControl(''),
  partitaIva: new FormControl(''),
  codiceFiscale: new FormControl(''),
  email: new FormControl(''),
  emailSecondaria: new FormControl(''),
  telefono: new FormControl(''),
  fax: new FormControl(''),
  sedeLegale: new FormControl(''),
  indirizzo: new FormControl(''),
  settoreAttivita: new FormControl(''),
  tipologiaSede: new FormControl(''),
  tipoSede: new FormControl(''),
  presenzaAzienda: new FormControl(''),
  numeroDipendenti: new FormControl(''),
  distribuzioneGenere: new FormControl(''),
  distribuzioneAnagrafica: new FormControl(''),
  

})
modificaAbilitata: Boolean

constructor(private aziendaService: AziendaService, private permissionService: PermissionService){
  const authModifica = permissionService.getGrantedPolicy('plb.Azienda.Update')
  this.modificaAbilitata = false;
}


gestisciModifica()
{
  !this.modificaAbilitata ? this.modificaAbilitata = true : this.modificaAbilitata = false
}

aggiornaAzienda(){
  var aziendaAgg = this.ModificaAziendaForm.value as UpdateAziendaDto
  aziendaAgg.denominazione_aziendale = !this.ModificaAziendaForm.value.denominazioneAziendale ? this.datiAzienda.denominazione_aziendale : this.ModificaAziendaForm.value.denominazioneAziendale
  aziendaAgg.partita_iva = !this.ModificaAziendaForm.value.partitaIva ? this.datiAzienda.partita_iva : this.ModificaAziendaForm.value.partitaIva
  aziendaAgg.codice_fiscale = !this.ModificaAziendaForm.value.codiceFiscale ? this.datiAzienda.codice_fiscale : this.ModificaAziendaForm.value.codiceFiscale
  aziendaAgg.email = !this.ModificaAziendaForm.value.email ? this.datiAzienda.email : this.ModificaAziendaForm.value.email
  aziendaAgg.emailsecondaria = !this.ModificaAziendaForm.value.emailSecondaria ? this.datiAzienda.emailsecondaria : this.ModificaAziendaForm.value.emailSecondaria
  aziendaAgg.telefono = !this.ModificaAziendaForm.value.telefono ? this.datiAzienda.telefono : this.ModificaAziendaForm.value.telefono
  aziendaAgg.fax = !this.ModificaAziendaForm.value.fax ? this.datiAzienda.fax : this.ModificaAziendaForm.value.fax
  aziendaAgg.sede_legale = !this.ModificaAziendaForm.value.sedeLegale ? this.datiAzienda.sede_legale : this.ModificaAziendaForm.value.sedeLegale
  aziendaAgg.indirizzo = !this.ModificaAziendaForm.value.indirizzo ? this.datiAzienda.indirizzo : this.ModificaAziendaForm.value.indirizzo
  aziendaAgg.settore_attivita = !this.ModificaAziendaForm.value.settoreAttivita ? this.datiAzienda.settore_attivita : this.ModificaAziendaForm.value.settoreAttivita
  aziendaAgg.tipologia_sede = !this.ModificaAziendaForm.value.tipologiaSede ? this.datiAzienda.tipologia_sede : this.ModificaAziendaForm.value.tipologiaSede
  aziendaAgg.tipo_sede = !this.ModificaAziendaForm.value.tipoSede ? this.datiAzienda.tipo_sede : this.ModificaAziendaForm.value.tipoSede
  aziendaAgg.presenza_azienda = !this.ModificaAziendaForm.value.presenzaAzienda ? this.datiAzienda.presenza_azienda : this.ModificaAziendaForm.value.presenzaAzienda
  aziendaAgg.num_dipendenti = !this.ModificaAziendaForm.value.numeroDipendenti ? this.datiAzienda.num_dipendenti : this.ModificaAziendaForm.value.numeroDipendenti
  aziendaAgg.distribuzione_genere = !this.ModificaAziendaForm.value.distribuzioneGenere ? this.datiAzienda.distribuzione_genere : this.ModificaAziendaForm.value.distribuzioneGenere
  aziendaAgg.distribuzione_anagrafica = !this.ModificaAziendaForm.value.distribuzioneAnagrafica ? this.datiAzienda.distribuzione_anagrafica : this.ModificaAziendaForm.value.distribuzioneAnagrafica
  
  this.aziendaService.update(this.datiAzienda.id, aziendaAgg).subscribe(()=>{
    this.aziendaAggiornata.emit();
    this.ModificaAziendaForm.reset();
    this.modificaAbilitata = false;
  })
}

}
