import { PermissionService, RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routesService: RoutesService) {
  return () => {
    routesService.add([
      {
        path: '/',
        name: 'Dashboard',
        iconClass: '',
        order: 2,
        requiredPolicy: "plb.Azienda" ,
        layout: eLayoutType.application,
      },
      {
        path: 'aziende',
        name: 'Aziende',
        iconClass: '',
        order: 1,
        requiredPolicy: "plb.Azienda",
        layout: eLayoutType.application,
      }    
    ]);
  };
}
